<template>
  <h1 class="text-h1 primary--text">
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'KTitle',
};
</script>
